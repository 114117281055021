@import "~@/assets/scss/variables.scss";


































































































































































































































































































































































































































































































































































@import '~leaflet/dist/leaflet.css';
@import '~leaflet-gesture-handling/dist/leaflet-gesture-handling.css';

.power-chart-card {
  flex-basis: 0;
  min-height: 400px;
}

.map-card {
  flex-basis: 0;
  min-height: 600px;
}

.site-summary-container {
  height: calc(100vh - 105px)
}

.highcharts-container {
  min-height: 0;
  min-width: 0;
}

.summary-radio label {
  cursor: pointer;
}

.summary-radio .active {
  background-color: $msi-orange !important;
  color: white !important;
  border-color: $msi-orange !important;
}

.overflow-hidden {
  overflow: hidden;
}

.module-view-multiselect {
  width: 200px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
}

.min-height-fit {
  min-height: fit-content;
}

.view-multiselect {
  width: 16rem;
}

.view-multiselect-show-all:hover {
  background: $msi-orange;
  color: white;
}
