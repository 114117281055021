// Variables
$msi-orange: #F47216;
$msi-orange-dark: darken($msi-orange, 10%);

$msi-white: #fff;
$msi-gray-100:  #f0f3f5;
$msi-gray-200:  #e4e7ea;
$msi-gray-300:  #c8ced3;
$msi-gray-400:  #acb4bc;
$msi-gray-500:  #8f9ba6;
$msi-gray-600:  #73818f;
$msi-gray-700:  #5c6873;
$msi-gray-800:  #2f353a;
$msi-gray-900:  #23282c;

$msi-success: #1cb150;
$msi-danger: #f72b2b;
$msi-warning: #ffc107;
$msi-info: #20a8d8;

// Variable Overrides
$primary: $msi-orange;
$info: $msi-info;

$sidebar-nav-color: #55595c;
$sidebar-nav-link-color: #55595c;
$sidebar-color: #55595c;
$sidebar-form-color: #55595c;
$sidebar-nav-title-color: #55595c;
$sidebar-nav-link-icon-color: #55595c;
$sidebar-nav-link-hover-color: #ffffff;
$sidebar-nav-link-hover-icon-color: #ffffff;
$sidebar-nav-link-active-color: #ffffff;
$sidebar-nav-link-active-icon-color: #55595c;
$sidebar-nav-dropdown-color: #55595c;
$sidebar-nav-dropdown-hover-color: #ffffff;
$sidebar-nav-dropdown-indicator-color: #55595c;
$sidebar-nav-dropdown-indicator-hover-color: #ffffff;
$sidebar-minimizer-indicator-color: #55595c;
$sidebar-minimizer-hover-indicator-color: #ffffff;
$sidebar-bg: #dedfe0;
$sidebar-nav-link-hover-bg: $msi-orange;
$sidebar-nav-link-active-bg: $msi-orange;
$sidebar-nav-dropdown-bg: none;
$sidebar-minimizer-bg: #d6d7d8;
$sidebar-minimizer-hover-bg: $msi-orange;
