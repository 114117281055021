@import "~@/assets/scss/variables.scss";





























































































































































































.date-btns {
  color: $msi-orange;
  cursor: pointer;
}

.date-btns-disabled {
  color: rgb(156, 98, 21);
  cursor: default;
  user-select: none;
  pointer-events: none;
}

.date-btns-text {
  font-size: 0.75rem;
}

.date-icon {
  width: 5px;
  height: 10px;
}

.summary-date.flatpickr-input {
  text-align: center;
  width: 100px;
}

.summary-date.flatpickr-input:disabled {
  cursor: default;
}
