@import "~@/assets/scss/variables.scss";








































































































































.context-menu {
  background: #f7f7f7;
  border: 1px solid #bdbdbd;
  box-shadow: 2px 2px 5px #757575;
  display: block;
  margin: 0;
  padding: 0;
  position: absolute;
  max-width: 200px;
  z-index: 1001;
}

.context-menu .header {
  font-size: 0.8rem;
  font-weight: 600;
  padding: 5px 15px 5px 15px;
  background: #ebebeb;
  border-bottom: 1px solid #bdbdbd;;
}

.context-menu ul {
  list-style: none;
  padding: 0 0;
  margin: 0;
}

.context-menu li {
  list-style: none;
  padding: 5px 0px 5px 15px;
  margin: 0;
  font-size: 0.75rem;
  font-weight: 400;
  cursor: pointer;
}

.context-menu li:hover {
  background: $msi-orange;
  color: #fafafa;
}

.context-menu-expanded li {
  list-style: none;
  padding: 5px 0px 5px 15px;
  margin: 0;
  font-size: 0.75rem;
  font-weight: 400;
  cursor: pointer;
  color: #000000;
}

.context-menu-expanded li:hover {
  background: $msi-orange;
  color: #fafafa;
}

.contextmenu-icon {
  padding-right: 5px;
  width: 15px;
  height: 15px;
}
.context-menu-expanded {
  background: #f7f7f7;
  border: 1px solid #bdbdbd;
  box-shadow: 2px 2px 5px #757575;
  display: block;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 150px;
  z-index: 1002;
}
