@import "~@/assets/scss/variables.scss";
























































































































.weather-carousel {
  background: rgba(0, 0, 0, 0.774);
}

.weather-carousel-btn {
  cursor: pointer;
  user-select: none;
  color: #686767;
}

.weather-icon-container-left {
  background-color: #d6d7d8;
  border-radius: 0.25rem 0 0 0.25rem;
  border: 1px solid #afafaf;
}

.weather-icon-container-right {
  background-color: #d6d7d8;
  border-radius: 0 0.25rem 0.25rem 0;
  border: 1px solid #afafaf;
}

.weather-date {
  font-size: 1.3rem;
  color: rgb(255, 255, 255);
  text-align: center;
}

.weather-icon {
  height: 60px;
  width: 60px;
  color: rgb(255, 255, 255);
}

.weather-temp {
  font-size: 1.8rem;
  color: rgb(255, 255, 255);
  text-align: center;
}

.weather-state {
  font-size: 1rem;
  color: rgb(255, 255, 255);
  text-align: center;
}

.weather-details-col {
  background: rgba(0, 0, 0, 0.774);
}

.weather-details-text {
  text-align: center;
  color: #fff;
  flex-basis: 50%;
  font-weight: 400;
  font-size: 0.9rem;
}

.openweather-link {
  position: absolute;
  bottom: 0;
  right: 0;
  text-decoration: none;
  padding-bottom: 4px;
  padding-right: 6px;
  font-size: 0.65rem;
  color: $msi-white
}

.openweather-link:hover {
  color: $msi-orange;
}

.no-select {
  user-select: none;
}

.request-in-progress {
  background: rgba(0, 0, 0, 0.774);
  min-height: 185px;
  color: white;
}

.no-data {
  background: rgba(0, 0, 0, 0.774);
  min-height: 50px;
  color: white;
}
